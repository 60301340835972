import { Button, Flex, Icon, Input, Link, Spinner, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from "react";
import { MdMarkEmailRead } from 'react-icons/md';
import { toast } from "react-toastify";
import { fetchWithHandling, formatDateDDmmYYYY } from "utils/common-utils";
import { Customer, Deadline, Notification, NotificationType, PagedDeadlines } from "utils/interfaces";
import keycloak from '../../../../utils/keycloak';
import Card from 'components/card/Card';

interface CustomerDetailOtherInfoProps {
    customer: Customer;
}

export default function CustomerDetailDeadlines({ customer }: CustomerDetailOtherInfoProps) {
    const [pagedDeadlines, setPagedDeadlines] = useState<PagedDeadlines | null>(null);
    const [loadingDeadlines, setLoadingDeadlines] = useState(false); // Stato di caricamento per le scadenze
    const [currentPage, setCurrentPage] = useState(1);
    const [inputPageNumber, setInputPageNumber] = useState('');
    // Error
    const [backendError, setBackendError] = useState(false);

    const jwtToken = localStorage.getItem('kc-token');
    const columnHelper = createColumnHelper<Deadline>();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

    const notificationTypes: Record<NotificationType, string> = {
        ONE_DAY_BEFORE: 'Giornaliera',
        ONE_WEEK_BEFORE: 'Settimanale',
        ONE_MONTH_BEFORE: 'Mensile',
    };

    useEffect(() => {
        const fetchDeadlines = async () => {
            try {
                setLoadingDeadlines(true);
                const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/${customer.id}/deadlines`;
                const jsonData = await fetchWithHandling(
                    uri,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    },
                    () => {
                        toast.error(
                            "Sessione scaduta. Effettua nuovamente l'accesso.", {
                            position: "bottom-right",
                            onClose: () => {
                                keycloak.logout();
                                localStorage.removeItem("kc-token");
                            }
                        }
                        );
                    }
                );

                setPagedDeadlines(jsonData);
            } catch (error) {
                console.error("Errore nella chiamata alle scadenze:", error);
                toast.error("Errore nel recupero delle scadenze.");
            } finally {
                setLoadingDeadlines(false);
            }
        };

        fetchDeadlines();
    }, []);

    useEffect(() => {
        fetchDeadlines(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        if (backendError) {
            toast.error(
                "Errore durante la chiamata al Server, perfavore riprova.", {
                position: "bottom-right",
            });
        }
    }, [backendError]);

    const buildURI = (
        pageNumber: number,
    ) => {
        const baseUrl = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/${customer.id}/deadlines`;
        let queryParams = [];
        if (pageNumber) {
            queryParams.push(`pageNumber=${pageNumber - 1}`);
        }
        let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
        const uri = baseUrl + queryString;
        return uri;
    };

    const fetchDeadlines = async (pageNumber?: number) => {
        setLoadingDeadlines(true);
        try {
            const uri = buildURI(pageNumber);

            const jsonData = await fetchWithHandling(
                uri,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                },
                () => {
                    toast.error(
                        "Sessione scaduta. Effettua nuovamente l'accesso.", {
                        position: "bottom-right",
                        onClose: () => {
                            keycloak.logout();
                            localStorage.removeItem("kc-token");
                        }
                    }
                    );
                }
            );

            if (jsonData) {
                setPagedDeadlines(jsonData);
            }

        } catch (error) {
            setBackendError(true); // Imposto un errore di backend se qualcosa va storto
        } finally {
            setLoadingDeadlines(false); // Disattivo lo stato di caricamento
        }
    };

    const columns = [
        columnHelper.accessor('serviceName', {
            id: 'serviceName',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    paddingInlineStart='var(--chakra-space-6)'
                    marginRight='auto'
                    fontSize={{ sm: '10px', lg: '14px' }}
                    textTransform="none"
                    color='gray.400'>
                    Nome Servizio
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text
                        color={textColor}
                        fontSize='xs'
                        fontWeight='700'
                    >
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('category', {
            id: 'category',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    marginLeft='auto'
                    marginRight='auto'
                    fontSize={{ sm: '10px', lg: '14px' }}
                    textTransform="none"
                    color='gray.400'
                    textAlign="center"
                >
                    Categoria
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    {info.getValue() != null && info.getValue().name != null && info.getValue().name !== "" ? (
                        <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                            {info.getValue().name}
                        </Text>
                    ) : (
                        <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                            -
                        </Text>
                    )}
                </Flex>
            )
        }),
        columnHelper.accessor('tipology', {
            id: 'tipology',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    marginLeft='auto'
                    marginRight='auto'
                    fontSize={{ sm: '10px', lg: '14px' }}
                    textTransform="none"
                    color='gray.400'
                    textAlign="center"
                >
                    Tipologia
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    {info.getValue() != null && info.getValue().name != null && info.getValue().name !== "" ? (
                        <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                            {info.getValue().name}
                        </Text>
                    ) : (
                        <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                            -
                        </Text>
                    )}
                </Flex>
            )
        }),
        columnHelper.accessor('insertDate', {
            id: 'insertDate',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    marginLeft='auto'
                    marginRight='auto'
                    fontSize={{ sm: '10px', lg: '14px' }}
                    textTransform="none"
                    color='gray.400'
                    textAlign="center"
                >
                    Data Inserimento
                </Text>
            ),
            cell: (info) => (
                <Flex align='center'>
                    {info.getValue() != null ? (
                        <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                            {formatDateDDmmYYYY(info.getValue())}
                        </Text>
                    ) : (
                        <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                            -
                        </Text>
                    )}
                </Flex>
            )
        }),
        columnHelper.accessor('deadlineDate', {
            id: 'deadlineDate',
            header: () => (
                <Text
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    margin='auto'
                    fontSize={{ sm: '10px', lg: '14px' }}
                    textTransform="none"
                    color='gray.400'
                    textAlign="center"
                >
                    <span>Data Scadenza</span>
                </Text>
            ),
            cell: (info) => (
                <Flex align='center'>
                    {info.getValue() != null ? (
                        <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                            {formatDateDDmmYYYY(info.getValue())}
                        </Text>
                    ) : (
                        <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                            -
                        </Text>
                    )}
                </Flex>
            )
        }),
        columnHelper.accessor('documentationRefs', {
            id: 'documentationRefs',
            header: () => (
                <Text
                    marginLeft='auto'
                    marginRight='auto'
                    justifyContent='center'
                    align='center'
                    fontSize={{ sm: '10px', lg: '14px' }}
                    textTransform="none"
                    color='gray.400'
                    textAlign="center"
                >
                    Link Doc.
                </Text>
            ),
            cell: (info: any) => (
                <Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
                    {info.row.original.documentationRefs ? (
                        <Link
                            href={info.row.original.documentationRefs}
                            isExternal
                            color="blue.500"
                            textDecoration="underline"
                            _hover={{ color: "blue.700" }}
                            fontSize='xs'
                            fontWeight='700'
                            marginLeft='auto'
                            marginRight='auto'
                            textAlign="center"
                        >
                            Link
                        </Link>
                    ) : (
                        "-"
                    )}
                </Text>
            )
        }),
        columnHelper.accessor('emailNotifications', {
            id: 'emailNotifications',
            header: () => (
                <Text
                    marginLeft='auto'
                    marginRight='auto'
                    justifyContent='center'
                    align='center'
                    fontSize={{ sm: '10px', lg: '14px' }}
                    textTransform="none"
                    color='gray.400'
                >
                    Notifiche Email
                </Text>
            ),
            cell: (info: any) => (
                <Flex justifyContent='center' alignItems='center'>
                    {renderNotificationIcons(info.row.original.emailNotifications)}

                </Flex>
            )
        })
    ];

    const renderNotificationIcons = (notifications: Notification[]) => {
        return (Object.keys(notificationTypes) as NotificationType[])
            .filter((type) => notifications?.some((notification) => notification.notificationType === type))
            .map((type) => {
                const notification: Notification = notifications.find((notification) => notification.notificationType === type);
                const isSent = notification?.sent;
                const sentDate = notification?.sentDate;
                const errorOccured = notification?.errorOccured;
                const errorMessage = notification?.errorMessage;

                let iconColor;
                let tooltipLabel;

                if (errorOccured === true && errorMessage) {
                    iconColor = 'var(--chakra-colors-red-500)';
                    tooltipLabel = `${notificationTypes[type]}, data di invio: ${new Date(sentDate).toLocaleString()}, errore: ${errorMessage}`;
                } else if (isSent === true) {
                    iconColor = 'var(--chakra-colors-green-500)';
                    tooltipLabel = `${notificationTypes[type]}, data di invio: ${new Date(sentDate).toLocaleString()}`;
                } else {
                    iconColor = 'gray.300';
                    tooltipLabel = notificationTypes[type];
                }

                return (
                    <Tooltip key={type} label={tooltipLabel} hasArrow>
                        <span tabIndex={0}>
                            <Icon as={MdMarkEmailRead} w={6} h={6} color={iconColor} mx="2" />
                        </span>
                    </Tooltip>
                );
            });
    };

    const table = useReactTable({
        columns,
        data: pagedDeadlines?.content,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: false, // Disabilita i log di debug della tabella
        debugHeaders: false, // Disabilita i log di debug degli header
        debugColumns: false  // Disabilita i log di debug delle colonne
    });

    const handlePageChange = (newPage: number) => {
        if (newPage >= 0 && newPage <= pagedDeadlines.totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <Card flexDirection='column' width={{ base: '100%', md: '96%' }} pt='25px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
            <Table variant='simple' color='gray.500'>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        pe='10px'
                                        borderColor={borderColor}
                                        paddingInline="0px"
                                    >
                                        <Flex
                                            justifyContent='space-between'
                                            align='center'
                                            fontSize={{ sm: '10px', lg: '12px' }}
                                            color='gray.400'>
                                            {flexRender(header.column.columnDef.header, header.getContext())}{{
                                                asc: '',
                                                desc: '',
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </Flex>
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {loadingDeadlines ? (
                        Array.from({ length: 15 }).map((_, rowIndex) => (
                            <Tr key={`loading-row-${rowIndex}`}>
                                {columns.map((column, columnIndex) => (
                                    <Td
                                        key={`loading-cell-${rowIndex}-${columnIndex}`}
                                        pb='6px'
                                        fontSize={{ sm: '14px' }}
                                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                        borderColor='transparent'
                                        textAlign="center"
                                    >
                                        {rowIndex === 0 ? <Spinner size="sm" color="gray.500" /> : null}
                                    </Td>
                                ))}
                            </Tr>
                        ))
                    ) : pagedDeadlines?.content?.length > 0 ? (
                        <>
                            {table.getRowModel().rows.map((row) => (
                                <Tr
                                    key={row.id}
                                    _hover={{ bg: 'gray.200' }}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <Td
                                            pb='2px'
                                            key={cell.id}
                                            fontSize={{ sm: '14px' }}
                                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                            borderColor='transparent'
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </>
                    ) : (
                        <Tr>
                            <Td colSpan={columns.length} textAlign="center">
                                Nessuna scadenza
                            </Td>
                        </Tr>
                    )}
                </Tbody>
                <Tfoot w="100%">
                    <Tr>
                        <Td colSpan={8} textAlign="center">
                            <Flex justifyContent="center" alignItems="center" mt={4}>
                                <Button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    isDisabled={pagedDeadlines?.pageable?.pageNumber === 0}
                                    height="100%"
                                >
                                    Precedente
                                </Button>
                                <Text mx={2}>
                                    Pagina {pagedDeadlines ? pagedDeadlines.pageable?.pageNumber + 1 : 0} di {pagedDeadlines ? pagedDeadlines.totalPages : 0}
                                </Text>
                                <Button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    isDisabled={pagedDeadlines?.pageable?.pageNumber === pagedDeadlines?.totalPages - 1}
                                    height="100%"
                                >
                                    Successiva
                                </Button>
                                <Flex alignItems="center" mx={2}>
                                    <Input
                                        type="number"
                                        placeholder="Pagina"
                                        value={inputPageNumber}
                                        onChange={(e) => setInputPageNumber(e.target.value)}
                                        width="90px"
                                        mx={2}
                                    />
                                    <Button
                                        onClick={() => {
                                            const pageNumber = parseInt(inputPageNumber, 10);
                                            if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= pagedDeadlines?.totalPages) {
                                                handlePageChange(pageNumber);
                                            }
                                        }}
                                    >
                                        Vai
                                    </Button>
                                </Flex>
                            </Flex>
                        </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </Card>
    );
}