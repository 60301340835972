import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from "@chakra-ui/react";
import { Customer } from "utils/interfaces";
import CustomerDetailDeadlines from "./CustomerDetailDeadlines";

interface CustomerDetailOtherInfoProps {
    customer: Customer;
}

export default function CustomerDetailOtherInfo({ customer }: CustomerDetailOtherInfoProps) {
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );

    return (
        <Box
            backgroundColor="white"
            borderRadius="20px"
            boxShadow={shadow}
            p="20px"
            mr={{ base: 0, md: 5 }}
            width="100%"
        >
            <Tabs variant="enclosed">
                <TabList>
                    <Tab>Scadenze</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <CustomerDetailDeadlines customer={customer} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}