import {
	Box, Button, Checkbox, Flex, Icon, Input, Link, Spinner, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useColorModeValue
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel, getFilteredRowModel, getPaginationRowModel,
	getSortedRowModel, useReactTable
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { format } from 'date-fns';
import Keycloak from 'keycloak-js';
import { useEffect, useState } from "react";
import { FaSync } from 'react-icons/fa';
import { MdMarkEmailRead } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithHandling } from 'utils/common-utils';
import { EmailNotificationReminder, NotificationType, PagedEmailNotificationReminder, Notification } from "../../../utils/interfaces";
import './pagination.css';

export default function NotificationDeadlinesReminderTable({
	keycloak,
}: {
	keycloak: Keycloak;
}) {
	const jwtToken = localStorage.getItem('kc-token');

	const [loading, setLoading] = useState(true);

	// Pagination
	const [pagedNotificationReminders, setEmailNotificationReminders] = useState<PagedEmailNotificationReminder | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [inputPageNumber, setInputPageNumber] = useState('');
	const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());

	// Error
	const [backendError, setBackendError] = useState(false);

	// Color
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);

	// Table
	const columnHelper = createColumnHelper<EmailNotificationReminder>();

	const notificationTypes: Record<NotificationType, string> = {
		ONE_DAY_BEFORE: 'Giornaliera',
		ONE_WEEK_BEFORE: 'Settimanale',
		ONE_MONTH_BEFORE: 'Mensile',
	};

	useEffect(() => {
		fetchEmailNotificationReminders(currentPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	useEffect(() => {
		if (backendError) {
			toast.error(
				"Errore durante la chiamata al Server, perfavore riprova.", {
				position: "bottom-right",
			});
		}
	}, [backendError]);

	const handlePageChange = (newPage: number) => {
		if (newPage >= 0 && newPage <= pagedNotificationReminders.totalPages) {
			setCurrentPage(newPage);
		}
	};

	const formatDateDDmmYYYY = (dateString: string): string => {
		const date = new Date(dateString);
		return format(date, 'dd-MM-yyyy');
	};

	const buildURI = (
		pageNumber: number,
		typedTransactionId: string = ''
	) => {
		let baseUrl = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/reminders`;
		let queryParams = [];

		if (pageNumber) {
			queryParams.push(`pageNumber=${pageNumber - 1}`);
		}

		let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
		const uri = baseUrl + queryString;

		return uri;
	};

	const fetchEmailNotificationReminders = async (pageNumber?: number, typedTransactionId?: string) => {
		setLoading(true);
		try {
			const uri = buildURI(pageNumber, typedTransactionId);
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setEmailNotificationReminders(jsonData);
			}

		} catch (error) {
			setBackendError(true); // Imposto un errore di backend se qualcosa va storto
		} finally {
			setLoading(false); // Disattivo lo stato di caricamento
		}
	};

	const deleteNotifications = async () => {
		if (selectedIds.size === 0) {
			toast.info("Nessuna scadenza selezionata.");
			return;
		}
		setLoading(true);
		try {
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/reminders`;
			const response = await fetchWithHandling(
				uri,
				{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
					body: JSON.stringify({
						ids: Array.from(selectedIds)
					}),
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (response.ok) {
				toast.success(
					"Scadenze evase con successo", {
					position: "bottom-right",
				}
				);
			} else {
				console.error("Errore dal server:", response);
				toast.error(
					"Errore durante l'aggiornamento delle scadenze non evase", {
					position: "bottom-right",
				});
			}

			// Ricarica i dati dopo l'aggiornamento
			fetchEmailNotificationReminders(currentPage);

		} catch (error) {
			toast.error(
				"Errore durante l'aggiornamento delle scadenze", {
				position: "bottom-right",
			});
		} finally {
			setLoading(false);
		}
	};

	const columns = [
		columnHelper.accessor('deadline.serviceName', {
			id: 'serviceName',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					paddingInlineStart='var(--chakra-space-6)'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'>
					Nome Servizio
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text
						color={textColor}
						fontSize='xs'
						fontWeight='700'
					>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('deadline.category', {
			id: 'category',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Categoria
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					{info.getValue() != null && info.getValue().name != null && info.getValue().name !== "" ? (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							{info.getValue().name}
						</Text>
					) : (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							-
						</Text>
					)}
				</Flex>
			)
		}),
		columnHelper.accessor('deadline.tipology', {
			id: 'tipology',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Tipologia
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					{info.getValue() != null && info.getValue().name != null && info.getValue().name !== "" ? (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							{info.getValue().name}
						</Text>
					) : (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							-
						</Text>
					)}
				</Flex>
			)
		}),
		columnHelper.accessor('deadline.deadlineDate', {
			id: 'deadlineDate',
			header: () => (
				<Text
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					margin='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					<span>Data Scadenza</span>
				</Text>
			),
			cell: (info) => (
				<Flex align='center'>
					{info.getValue() != null ? (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							{formatDateDDmmYYYY(info.getValue())}
						</Text>
					) : (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							-
						</Text>
					)}
				</Flex>
			)
		}),
		columnHelper.accessor('deadline.documentationRefs', {
			id: 'documentationRefs',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Link Doc.
				</Text>
			),
			cell: (info: any) => (
				<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
					{info.row.original.deadline?.documentationRefs ? (
						<Link
							href={info.row.original.deadline?.documentationRefs}
							isExternal
							color="blue.500"
							textDecoration="underline"
							_hover={{ color: "blue.700" }}
							fontSize='xs'
							fontWeight='700'
							marginLeft='auto'
							marginRight='auto'
							textAlign="center"
						>
							Link
						</Link>
					) : (
						"-"
					)}
				</Text>
			)
		}),
		columnHelper.accessor('deadline.emailNotifications', {
			id: 'emailNotifications',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
				>
					Notifiche Email
				</Text>
			),
			cell: (info: any) => (
				<Flex justifyContent='center' alignItems='center'>
					{renderNotificationIcons(info.row.original.deadline?.emailNotifications)}
				</Flex>
			)
		}),
		{
			id: 'operation',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
				>
					Evasa
				</Text>
			),
			cell: (info: any) => (
				<Flex justifyContent='center' alignItems='center'>
					<Checkbox
						 sx={{
							".chakra-checkbox__control": {
							  borderColor: "gray.500", // Colore del bordo della checkbox
							},
							".chakra-checkbox__control[data-checked]": {
							  bg: "#688CC8",  // Colore dello sfondo quando selezionata
							  borderColor: "#688CC8", // Colore del bordo quando selezionata
							},
						  }}
						isChecked={selectedIds.has(info.row.original.id)}
						onChange={() => {
							const id = info.row.original.id;
							setSelectedIds(prev => {
								const newSet = new Set(prev);
								if (newSet.has(id)) {
									newSet.delete(id);
								} else {
									newSet.add(id);
								}
								return newSet;
							});
						}}
					/>
				</Flex>
			)
		}
	];

	const table = useReactTable({
		columns,
		data: pagedNotificationReminders?.content,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		debugTable: false, // Disabilita i log di debug della tabella
		debugHeaders: false, // Disabilita i log di debug degli header
		debugColumns: false  // Disabilita i log di debug delle colonne
	});

	const renderNotificationIcons = (notifications: Notification[]) => {
		return (Object.keys(notificationTypes) as NotificationType[])
			.filter((type) => notifications?.some((notification) => notification.notificationType === type))
			.map((type) => {
				const notification = notifications.find((notification) => notification.notificationType === type);
				const isSent = notification?.sent;
				const sentDate = notification?.sentDate;
				const errorOccured = notification?.errorOccured;
				const errorMessage = notification?.errorMessage;

				let iconColor;
				let tooltipLabel;

				if (errorOccured === true && errorMessage) {
					iconColor = 'var(--chakra-colors-red-500)';
					tooltipLabel = `${notificationTypes[type]}, data di invio: ${new Date(sentDate).toLocaleString()}, errore: ${errorMessage}`;
				} else if (isSent === true) {
					iconColor = 'var(--chakra-colors-green-500)';
					tooltipLabel = `${notificationTypes[type]}, data di invio: ${new Date(sentDate).toLocaleString()}`;
				} else {
					iconColor = 'gray.300';
					tooltipLabel = notificationTypes[type];
				}

				return (
					<Tooltip key={type} label={tooltipLabel} hasArrow>
						<span tabIndex={0}>
							<Icon as={MdMarkEmailRead} w={6} h={6} color={iconColor} mx="2" />
						</span>
					</Tooltip>
				);
			});
	};


	return (
		<Card background='none' p="0px">
			<ToastContainer />
			<Card boxShadow={shadow} flexDirection='column' width={{ base: '100%', md: '96%' }} pt='25px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
				<Flex
					px='25px'
					mb="8px"
					justifyContent='space-between'
					align='center'
				>
					<Text color="#9FB50C" fontSize='22px' fontWeight='700' lineHeight='100%'>
						Scadenze non Evase
					</Text>
					<Flex ml={{ base: '20px', sm: '0auto' }} >
						<Button
							_hover={{ bg: 'gray.700' }}
							_active={{ bg: 'gray.600' }}
							_focus={{ boxShadow: 'none' }}
							backgroundColor='#688CC8'
							color="white"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer',
							}}
							fontSize='xs'
							mx="1"
							onClick={deleteNotifications}
						>
							Evadi Scadenze
						</Button>
						<Button
							onClick={() => fetchEmailNotificationReminders()}
							variant="ghost"
							colorScheme="blue"
							mx="1"
							p={2}
							_hover={{ bg: 'gray.200' }}
						>
							<Icon as={FaSync} w={5} h={5} />
						</Button>
					</Flex>
				</Flex>
				<Box>
					<Table variant='simple' color='gray.500' mb='24px' mt="12px" w='100%'>
						<Thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<Th
												key={header.id}
												colSpan={header.colSpan}
												pe='10px'
												borderColor={borderColor}
												paddingInline="0px"
											>
												<Flex
													justifyContent='space-between'
													align='center'
													fontSize={{ sm: '10px', lg: '12px' }}
													color='gray.400'>
													{flexRender(header.column.columnDef.header, header.getContext())}{{
														asc: '',
														desc: '',
													}[header.column.getIsSorted() as string] ?? null}
												</Flex>
											</Th>
										);
									})}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{loading ? (
								Array.from({ length: 15 }).map((_, rowIndex) => (
									<Tr key={`loading-row-${rowIndex}`}>
										{columns.map((column, columnIndex) => (
											<Td
												key={`loading-cell-${rowIndex}-${columnIndex}`}
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'
												textAlign="center"
											>
												{rowIndex === 0 ? <Spinner size="sm" color="gray.500" /> : null}
											</Td>
										))}
									</Tr>
								))
							) : pagedNotificationReminders?.content?.length > 0 && table.getRowModel().rows.length > 0 ? (
								<>
									{table.getRowModel().rows.map((row) => (
										<Tr
											key={row.id}
											_hover={{ bg: 'gray.200' }}
										>
											{row.getVisibleCells().map((cell) => (
												<Td
													pb='0px'
													key={cell.id}
													fontSize={{ sm: '14px' }}
													minW={{ sm: '150px', md: '200px', lg: 'auto' }}
													borderColor='transparent'
												>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</Td>
											))}
										</Tr>
									))}
								</>
							) : (
								<Tr>
									<Td colSpan={columns.length} textAlign="center">
										Nessuna scadenza non evasa
									</Td>
								</Tr>
							)}
						</Tbody>
						<Tfoot w="100%">
							<Tr>
								<Td colSpan={8} textAlign="center">
									<Flex justifyContent="center" alignItems="center" mt={4}>
										<Button
											onClick={() => handlePageChange(currentPage - 1)}
											isDisabled={pagedNotificationReminders?.pageable?.pageNumber === 0}
											height="100%"
										>
											Precedente
										</Button>
										<Text mx={2}>
											Pagina {pagedNotificationReminders ? pagedNotificationReminders.pageable?.pageNumber + 1 : 0} di {pagedNotificationReminders ? pagedNotificationReminders.totalPages : 0}
										</Text>
										<Button
											onClick={() => handlePageChange(currentPage + 1)}
											isDisabled={pagedNotificationReminders?.pageable?.pageNumber === pagedNotificationReminders?.totalPages - 1}
											height="100%"
										>
											Successiva
										</Button>
										<Flex alignItems="center" mx={2}>
											<Input
												type="number"
												placeholder="Pagina"
												value={inputPageNumber}
												onChange={(e) => setInputPageNumber(e.target.value)}
												width="90px"
												mx={2}
											/>
											<Button
												onClick={() => {
													const pageNumber = parseInt(inputPageNumber, 10);
													if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= pagedNotificationReminders?.totalPages) {
														handlePageChange(pageNumber);
													}
												}}
											>
												Vai
											</Button>
										</Flex>
									</Flex>
								</Td>
							</Tr>
						</Tfoot>
					</Table>
				</Box>
			</Card>
		</Card>
	);
}