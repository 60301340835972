import { format } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Utility per gestire le chiamate HTTP e gli errori con async/await
export const fetchWithHandling = async (
    uri: string,
    options: RequestInit,
    onUnauthorized: () => void,
    isNotJsonResponse?: boolean
): Promise<any | null> => {
    try {
        const response = await fetch(uri, options);

        // Gestione dello stato HTTP 401 (Unauthorized)
        if (response.status === 401) {
            onUnauthorized();
            return null; // Ritorna null in caso di errore 401
        }

        // Gestione di altri errori HTTP (da 400 a 599)
        if (response.status >= 400 && response.status < 600) {
            let errorMessage = "Si è verificato un errore. Riprova.";
            if (response.status === 403) {
                errorMessage = "Accesso negato. Non hai i permessi necessari.";
            } else if (response.status === 404) {
                errorMessage = "Risorsa non trovata.";
            } else if (response.status === 500) {
                errorMessage = "Errore interno del server. Riprova.";
            }

            toast.error(errorMessage, {
                position: "bottom-right"
            });

            return null; // Ritorna null in caso di errori
        }

        if (options.method !== "DELETE") {
            if (isNotJsonResponse) {
                return await response;
            } else {
                return await response.json();
            }
        } else {
            return response;
        }

    } catch (error) {
        console.error("Errore nella chiamata HTTP: ", error);

        toast.error("Errore di rete. Riprova più tardi.", {
            position: "bottom-right"
        });

        return null; // Ritorna null in caso di eccezioni
    }
};

export const formatDateDDmmYYYY = (dateString: string): string => {
    const date = new Date(dateString);
    return format(date, 'dd-MM-yyyy');
};

export const formatDateYYYYmmDD = (dateString: string): string => {
    const date = new Date(dateString);
    return format(date, 'yyyy-MM-dd');
};

// Funzione per validare il Codice Fiscale
export function validateItalianTaxCode(taxCode: string): boolean {
    const taxCodeRegex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;
    return taxCodeRegex.test(taxCode.toUpperCase());
}

// Funzione per validare la Partita IVA
export function validateItalianVAT(vatNumber: string): boolean {
    // Controlla se la Partita IVA è composta esattamente da 11 cifre numeriche
    return /^\d{11}$/.test(vatNumber);
}

export function validateEmail(email: string): boolean {
    // Regex per verificare la validità di un'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export const bigCalendarMessages = {
    date: "Data",
    time: "Ora",
    event: "Evento",
    allDay: "Tutto il giorno",
    week: "Settimana",
    work_week: "Settimana lavorativa",
    day: "Giorno",
    month: "Mese",
    previous: "Precedente",
    next: "Successivo",
    yesterday: "Ieri",
    tomorrow: "Domani",
    today: "Oggi",
    agenda: "Agenda",
    noEventsInRange: "Non ci sono eventi in questo intervallo",
    showMore: (count: any) => `+ altri ${count}`,
};