import {
  Box
} from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import CustomersTable from "./components/CustomersTable";

export default function Customers() {

  const { keycloak } = useKeycloak();

  return (
    <Box pt={{ base: "40px", md: "80px", xl: "78px" }}>
        <CustomersTable keycloak={keycloak} />
    </Box>
  );
}
