import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { Customer } from "utils/interfaces";
import userAvatar from './../../../../utils/assets/img/user-avatar.png';

interface CustomerDetailPersonalInfoProps {
  customer: Customer;
}

const CustomerDetailPersonalInfo: React.FC<CustomerDetailPersonalInfoProps> = ({ customer }) => {
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  return (
    <Box
      backgroundColor="white"
      borderRadius="20px"
      boxShadow={shadow}
      p="20px"
    >
      <Flex direction="column" align="center" mb="4">
        <Image
          borderRadius="full"
          boxSize="200px"
          src={userAvatar}
          alt="Avatar Utente"
        />
        <Text fontSize="xl" fontWeight="bold" color="#9FB50C" textAlign={"center"}>
          {customer.fullName}
        </Text>
      </Flex>

      <Box mt="50px">
        <Text color="gray.500" fontSize="sm">Indirizzo</Text>
        <Text color="black" fontSize="md" mb="3">{customer.address || '-'}</Text>

        <Text color="gray.500" fontSize="sm">Email</Text>
        <Text color="black" fontSize="md" mb="3">{customer.email || '-'}</Text>

        <Text color="gray.500" fontSize="sm">Telefono</Text>
        <Text color="black" fontSize="md" mb="3">{customer.phone || '-'}</Text>

        <Text color="gray.500" fontSize="sm">P. IVA</Text>
        <Text color="black" fontSize="md" mb="3">{customer.vatNumber || '-'}</Text>

        <Text color="gray.500" fontSize="sm">Cod. Fiscale</Text>
        <Text color="black" fontSize="md" mb="3">{customer.taxCode || '-'}</Text>
      </Box>
    </Box>
  );
};

export default CustomerDetailPersonalInfo;
