import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    useDisclosure,
    FormControl,
    Text,
    Spinner
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import Keycloak from 'keycloak-js';
import { fetchWithHandling } from 'utils/common-utils';
import { saveAs } from "file-saver";

export default function ImportExcelButton({
    keycloak,
    onImportSuccess
}: {
    keycloak: Keycloak;
    onImportSuccess?: () => void;
}) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false); // Stato per il caricamento
    const { isOpen, onOpen, onClose } = useDisclosure();
    const jwtToken = localStorage.getItem('kc-token');

    // Funzione per gestire la selezione del file
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Ottieni il primo file selezionato
        if (file) {
            setSelectedFile(file); // Memorizza il file selezionato nello stato
        }
    };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            toast.error("Seleziona un file prima di continuare.", { position: "bottom-right" });
            return;
        }

        const formData = new FormData();
        formData.append('excelFile', selectedFile); // Aggiungi il file al FormData

        setLoading(true); // Inizia il caricamento

        try {
            const response = await fetchWithHandling(
                `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines/import-excel`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                    body: formData, // Invia il FormData con il file
                },
                () => {
                    toast.error(
                        "Sessione scaduta. Effettua nuovamente l'accesso.", {
                        position: "bottom-right",
                        onClose: () => {
                            keycloak.logout();
                            localStorage.removeItem("kc-token");
                        }
                    }
                    );
                },
                true
            );

            if (response) {
                toast.success("File importato con successo!", { position: "bottom-right" });
            } else {
                toast.error("Errore durante il caricamento dell'excel o di alcune righe", { position: "bottom-right" });
            }

            onImportSuccess();
            onClose(); // Chiudi la modale

        } catch (error) {
            toast.error("Errore durante l'invio del file.", { position: "bottom-right" });
            console.error('Errore:', error);
        } finally {
            setLoading(false); // Termina il caricamento
        }
    };

    const handleImportExcelTemplateFileDownload = () => {
        saveAs("/assets/template-import-scadenze.xlsx", "template-import-scadenze.xlsx");
    };

    return (
        <>
            <Button
                _hover={{ bg: 'gray.700' }}
                _active={{ bg: 'gray.600' }}
                _focus={{ boxShadow: 'none' }}
                backgroundColor='#688CC8'
                color="white"
                style={{
                    height: '30px',
                    padding: '0px 12px',
                    borderRadius: '6px',
                    border: 'none',
                    cursor: 'pointer',
                }}
                fontSize='xs'
                mx="1"
                onClick={onOpen}
            >
                Importa Excel
            </Button>

            {/* Modale per il caricamento del file */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader color='primary'>Importa File Excel</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <Input
                                id="file-upload"
                                type="file"
                                accept=".xlsx, .xls"
                                onChange={handleFileChange}
                                mb={2}
                                borderColor="gray.300"
                                borderStyle="dashed"
                                _hover={{ borderColor: 'gray.400' }}
                                _focus={{ borderColor: '#688CC8', boxShadow: '0 0 0 1px #688CC8' }}
                                pt="5px"
                            />
                            <Text fontSize="sm" color="gray.500" mt={2}>
                                File accettati: .xlsx, .xls
                            </Text>
                            <Text fontSize="sm" color="red" mt={2} fontWeight={'bold'}>
                                ATTENZIONE: utilizza il <span
                                    onClick={handleImportExcelTemplateFileDownload}
                                    style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>
                                    template precompilato
                                </span>
                            </Text>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            _hover={{ bg: 'gray.700' }}
                            _active={{ bg: 'gray.600' }}
                            _focus={{ boxShadow: 'none' }}
                            color='white'
                            backgroundColor='#688CC8'
                            mr={3}
                            onClick={handleFileUpload}
                            isDisabled={loading} // Disabilita il pulsante se in caricamento
                        >
                            {loading ? <Spinner size="sm" /> : 'Carica'}
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            Annulla
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}