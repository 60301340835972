import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithHandling } from 'utils/common-utils';
import { Customer } from "utils/interfaces";
import CustomerDetailPersonalInfo from './components/CustomerDetailOtherInfoTabs/CustomerDetailPersonalInfo';
import CustomerDetailOtherInfo from './components/CustomerDetailOtherInfoTabs/CustomerDetailOtherInfo';

interface RouteParams {
  id: string;
}

export default function CustomerDetail() {
  const { keycloak } = useKeycloak();
  const { id } = useParams<RouteParams>();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);

  const jwtToken = localStorage.getItem('kc-token');

  useEffect(() => {
    const fetchCustomerDetail = async () => {
      try {
        setLoading(true);
        const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/${id}`;
        const jsonData = await fetchWithHandling(
          uri,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtToken}`,
            },
          },
          () => {
            toast.error("Sessione scaduta. Effettua nuovamente l'accesso.", {
              position: "bottom-right",
              onClose: () => {
                keycloak.logout();
                localStorage.removeItem("kc-token");
              }
            });
          }
        );
        setCustomer(jsonData);
      } catch (error) {
        console.error("Errore:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerDetail();
  }, [id, jwtToken, keycloak]);

  if (loading) {
    return (
      <Box pt={{ base: "40px", md: "80px", xl: "78px" }}>
        <Spinner size="lg" />
      </Box>
    );
  }

  if (!customer) {
    return (
      <Box pt={{ base: "40px", md: "80px", xl: "78px" }}>
        <Text color="red.500">Cliente non trovato</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "60px", md: "80px", xl: "78px" }}>
      <Flex direction={{ base: 'column', md: 'row' }} gap="20px" width="100%">
        <CustomerDetailPersonalInfo customer={customer} />
        <CustomerDetailOtherInfo customer={customer}/>
      </Flex>
    </Box>
  );
}