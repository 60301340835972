import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
    Box,
    Button, Checkbox, Flex, Icon, Input, InputGroup, InputRightElement, Menu,
    MenuButton, MenuItem, MenuList, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Spinner, Text, Tooltip, useColorModeValue
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { addDays, addMonths, addWeeks } from 'date-fns';
import Keycloak from 'keycloak-js';
import moment from "moment";
import "moment/locale/it";
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FaSync } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithHandling } from 'utils/common-utils';
import { bigCalendarMessages, formatDateYYYYmmDD } from "../../../utils/common-utils";
import { Customer, Deadline, DeadlinesCategoryType, DeadlinesTipologyType, EmailTemplate, Notification, NotificationType, PagedCustomers } from "../../../utils/interfaces"; // Interfaccia Deadline
import debounce from "lodash.debounce";

const DeadlinesCalendar = ({
    keycloak,
    deadlinesCategory,
    deadlinesTipology,
}: {
    keycloak: Keycloak;
    deadlinesCategory: DeadlinesCategoryType[];
    deadlinesTipology: DeadlinesTipologyType[];
}) => {
    const jwtToken = localStorage.getItem('kc-token');

    const [events, setEvents] = useState([]);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    // Add Modal
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [serviceName, setServiceName] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedTipologyId, setSelectedTipologyId] = useState(null);
    const [deadlineDate, setDeadlineDate] = useState('');
    const [documentationRefs, setDocumentationRefs] = useState('');
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    const [notifications, setNotifications] = useState({
        oneDayBefore: false,
        oneWeekBefore: false,
        oneMonthBefore: false,
    });

    // Edit Modal
    const [editId, setEditId] = useState(null);
    const [newServiceName, setNewServiceName] = useState('');
    const [newSelectedCategoryId, setNewSelectedCategoryId] = useState(null);
    const [newSelectedTipologyId, setNewSelectedTipologyId] = useState(null);
    const [newDeadlineDate, setNewDeadlineDate] = useState('');
    const [newInsertDate, setNewInsertDate] = useState('');
    const [newEmailNotifications, setNewEmailNotifications] = useState<Notification[]>([]);

    // Email Template
    const [searchedEmailTemplate, setSearchedEmailTemplate] = useState('');
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplate>();
    const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]); // Correctly initialized as an array

    const [loading, setLoading] = useState(false);
    const [backendError, setBackendError] = useState(false);

    // Customer SearchBox
    const [searchedCustomer, setSearchedCustomer] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
    const [customers, setCustomers] = useState<PagedCustomers>(); // Correctly initialized as an array

    const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
        startDate: null,
        endDate: null
    });

    moment.locale("it");
    const localizer = momentLocalizer(moment);


    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    useEffect(() => {
        fetchDeadlines(moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateAddModalForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceName, selectedCategoryId, selectedTipologyId, deadlineDate]);

    useEffect(() => {
        validateEditModalForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newServiceName, newSelectedCategoryId, newSelectedTipologyId, newDeadlineDate]);

    useEffect(() => {
        if (backendError) {
            toast.error(
                "Errore durante la chiamata al Server, perfavore riprova.", {
                position: "bottom-right",
            });
        }
    }, [backendError]);

    // Funzione per caricare gli eventi
    const fetchDeadlines = async (startDate: string, endDate: string) => {
        setLoading(true);
        try {
            let uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines?startDate=${startDate}&endDate=${endDate}`;
            const jsonData = await fetchWithHandling(
                uri,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${jwtToken}`,
                    },
                },
                () => {
                    toast.error(
                        "Sessione scaduta. Effettua nuovamente l'accesso.", {
                        position: "bottom-right",
                        onClose: () => {
                            keycloak.logout();
                            localStorage.removeItem("kc-token");
                        }
                    }
                    );
                }
            );

            if (jsonData) {
                const mappedEvents = jsonData.map((deadline: Deadline) => ({
                    id: deadline.id,
                    title: deadline.serviceName,
                    serviceName: deadline.serviceName,
                    start: new Date(deadline.deadlineDate),
                    end: new Date(deadline.deadlineDate),
                    deadlineDate: deadline.deadlineDate,
                    insertDate: deadline.insertDate,
                    category: deadline.category,
                    tipology: deadline.tipology,
                    documentationRefs: deadline.documentationRefs,
                    emailNotifications: deadline.emailNotifications,
                    customer: deadline.customer,
                    emailTemplate: deadline.emailTemplate
                }));
                setEvents(mappedEvents);
            }
        } catch (error) {
            setBackendError(true);
        } finally {
            setLoading(false);
        }
    };

    const calculateNotificationDate = (deadlineDate: string, notificationType: string): string | null => {
        const deadline = new Date(deadlineDate);

        switch (notificationType) {
            case 'ONE_DAY_BEFORE':
                return addDays(deadline, -1).toISOString();
            case 'ONE_WEEK_BEFORE':
                return addWeeks(deadline, -1).toISOString();
            case 'ONE_MONTH_BEFORE':
                return addMonths(deadline, -1).toISOString();
            default:
                return null;
        }
    };

    const handleRangeChange = debounce((range: { start: Date; end: Date } | Date[]) => {
        if (Array.isArray(range)) {
            const start = range[0];
            const end = range[range.length - 1];
            setDateRange({ startDate: start, endDate: end });
            fetchDeadlines(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"));
        } else {
            const { start, end } = range;
            setDateRange({ startDate: start, endDate: end });
            fetchDeadlines(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"));
        }
    }, 400);

    const openAddModal = (slotInfo: any) => {
        const clickedDate = slotInfo.start;
        const formattedDate = new Date(clickedDate).toLocaleDateString('en-CA', {
            timeZone: 'Europe/Rome',
        });
        setDeadlineDate(formattedDate);
        setIsAddModalOpen(true);
    };

    const validateEditModalForm = () => {
        if (newServiceName && newSelectedCategoryId && newSelectedTipologyId && newDeadlineDate) {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    };

    const validateAddModalForm = () => {
        if (serviceName && selectedCategoryId && selectedTipologyId && deadlineDate) {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
        setServiceName('');
        setSelectedCategoryId(null);
        setSelectedTipologyId(null);
        setDeadlineDate('');
        setDocumentationRefs("");
        setSelectedCustomer(null);
        setSelectedEmailTemplate(null);
        setNotifications({
            oneDayBefore: false,
            oneWeekBefore: false,
            oneMonthBefore: false,
        });
        setEmailTemplates([]);
        setCustomers(null);
        setSearchedCustomer("");
        setSearchedEmailTemplate("");
    };

    const truncateName = (name: string, maxLength: number = 20): string => {
        if (name.length <= maxLength) return name;
        const start = name.slice(0, maxLength - 3);
        return `${start}...`;
    };

    const fetchCustomers = async (fullName?: string) => {
        try {
            let baseUrl = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/paginated`;
            let queryParams = [];

            if (fullName) {
                queryParams.push(`fullName=${fullName}`);
            }

            let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
            const uri = baseUrl + queryString;

            const jsonData = await fetchWithHandling(
                uri,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                },
                () => {
                    toast.error(
                        "Sessione scaduta. Effettua nuovamente l'accesso.", {
                        position: "bottom-right",
                        onClose: () => {
                            keycloak.logout();
                            localStorage.removeItem("kc-token");
                        }
                    }
                    );
                }
            );

            if (jsonData) {
                setCustomers(jsonData);
            }

        } catch (error) {
            setBackendError(true); // Imposto un errore di backend se qualcosa va storto
        }
    };

    const addDeadline = async () => {
        try {
            setLoading(true);
            const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines`;

            const emailNotifications = [];
            if (notifications.oneDayBefore) {
                emailNotifications.push({
                    notificationType: 'ONE_DAY_BEFORE',
                    notificationDate: calculateNotificationDate(deadlineDate, 'ONE_DAY_BEFORE')
                });
            }
            if (notifications.oneWeekBefore) {
                emailNotifications.push({
                    notificationType: 'ONE_WEEK_BEFORE',
                    notificationDate: calculateNotificationDate(deadlineDate, 'ONE_WEEK_BEFORE')
                });
            }
            if (notifications.oneMonthBefore) {
                emailNotifications.push({
                    notificationType: 'ONE_MONTH_BEFORE',
                    notificationDate: calculateNotificationDate(deadlineDate, 'ONE_MONTH_BEFORE')
                });
            }

            const requestBody = {
                serviceName,
                insertDate: new Date().toISOString(),
                deadlineDate,
                documentationRefs,
                category: { id: selectedCategoryId },
                tipology: { id: selectedTipologyId },
                emailNotifications,
                ...(selectedCustomer && { customer: { id: selectedCustomer.id } }),
                ...(selectedEmailTemplate && { emailTemplate: { id: selectedEmailTemplate.id } })
            };

            setServiceName('');
            setSelectedCategoryId(null);
            setSelectedTipologyId(null);
            setDeadlineDate('');
            setDocumentationRefs("");
            setSelectedCustomer(null);
            setSelectedEmailTemplate(null);
            setNotifications({
                oneDayBefore: false,
                oneWeekBefore: false,
                oneMonthBefore: false,
            });

            const jsonData = await fetchWithHandling(
                uri,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                    body: JSON.stringify(requestBody)
                },
                () => {
                    toast.error(
                        "Sessione scaduta. Effettua nuovamente l'accesso.", {
                        position: "bottom-right",
                        onClose: () => {
                            keycloak.logout();
                            localStorage.removeItem("kc-token");
                        }
                    }
                    );
                },
            );

            if (jsonData) {
                toast.success(
                    "Scadenza aggiunta con successo", {
                    position: "bottom-right",
                }
                );
                fetchDeadlines(moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD"));
                closeAddModal();
            } else {
                toast.error(
                    "Errore durante l'aggiunta della scadenza", {
                    position: "bottom-right",
                });
            }

            setLoading(false);
        } catch (error) {
            setBackendError(true);
            setLoading(false);
        }
    };

    const fetchEmailTemplates = async () => {
        try {
            const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/email-templates`;
            const jsonData = await fetchWithHandling(
                uri,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                },
                () => {
                    toast.error(
                        "Sessione scaduta. Effettua nuovamente l'accesso.", {
                        position: "bottom-right",
                        onClose: () => {
                            keycloak.logout();
                            localStorage.removeItem("kc-token");
                        }
                    }
                    );
                }
            );

            if (jsonData) {
                setEmailTemplates(jsonData);
            }

        } catch (error) {
            setBackendError(true); // Imposto un errore di backend se qualcosa va storto
        }
    };

    const filteredEmailTemplate = emailTemplates?.filter((template: EmailTemplate) =>
        template.name.toLowerCase().includes(searchedEmailTemplate.toLowerCase())
    );

    const handleCustomerSelect = (option: Customer) => {
        setSelectedCustomer(option);
    };

    const handleEmailTemplateSelect = (option: EmailTemplate) => {
        setSelectedEmailTemplate(option);
    };

    const handleCheckboxChange = (event: any) => {
        const { name, checked } = event.target;
        setNotifications((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const openEditModal = (event: any) => {
        setEditId(event.id);
        setNewServiceName(event.serviceName);
        setNewSelectedCategoryId(event.category.id);
        setNewSelectedTipologyId(event.tipology.id);
        setNewDeadlineDate(event.deadlineDate);
        setNewInsertDate(event.insertDate);
        setDocumentationRefs(event.documentationRefs);
        setNewEmailNotifications(event.emailNotifications);
        setNotifications(mapNotifications(event.emailNotifications));
        setSelectedCustomer(event.customer);
        setSelectedEmailTemplate(event.emailTemplate)
    };

    const confirmDelete = () => {
        closeDeleteModal();
        deleteDeadline(selectedId);
    };

    const deleteDeadline = async (id: number) => {
        try {
            setLoading(true);

            const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines/${id}`;
            const response = await fetchWithHandling(
                uri,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                },
                () => {
                    toast.error(
                        "Sessione scaduta. Effettua nuovamente l'accesso.", {
                        position: "bottom-right",
                        onClose: () => {
                            keycloak.logout();
                            localStorage.removeItem("kc-token");
                        }
                    }
                    );
                }
            );

            fetchDeadlines(moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD"));

            if (response.ok) {
                toast.success(
                    "Scadenza eliminata con successo", {
                    position: "bottom-right",
                }
                );
            } else {
                console.error("Errore dal server:", response);
                toast.error(
                    "Errore durante l'eliminazione della scadenza", {
                    position: "bottom-right",
                });
            }

        } catch (error) {
            setBackendError(true);
        } finally {
            setLoading(false);
        }
    };

    const closeEditModal = () => {
        setEditId(null);
        setNewServiceName("");
        setNewSelectedCategoryId(null);
        setNewSelectedTipologyId(null);
        setNewDeadlineDate("");
        setNewInsertDate("");
        setDocumentationRefs("");
        setNewEmailNotifications([]);
        setNotifications({
            oneDayBefore: false,
            oneWeekBefore: false,
            oneMonthBefore: false,
        });
        setSelectedCustomer(null);
        setSelectedEmailTemplate(null);
        setEmailTemplates([]);
        setCustomers(null);
        setSearchedCustomer("");
        setSearchedEmailTemplate("");
    };

    const openDeleteModal = () => {
        setSelectedId(editId);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        closeEditModal();
        setIsDeleteModalOpen(false);
        setSelectedId(null);
    };

    const mapNotifications = (emailNotifications: Notification[]) => {
        if (emailNotifications) {
            return {
                oneDayBefore: emailNotifications.some(n => n.notificationType === 'ONE_DAY_BEFORE'),
                oneWeekBefore: emailNotifications.some(n => n.notificationType === 'ONE_WEEK_BEFORE'),
                oneMonthBefore: emailNotifications.some(n => n.notificationType === 'ONE_MONTH_BEFORE'),
            };
        }
    };

    const editDeadline = async () => {
        try {
            setLoading(true);

            const notificationTypes: { type: NotificationType, value: boolean }[] = [
                { type: 'ONE_DAY_BEFORE' as NotificationType, value: notifications.oneDayBefore },
                { type: 'ONE_WEEK_BEFORE' as NotificationType, value: notifications.oneWeekBefore },
                { type: 'ONE_MONTH_BEFORE' as NotificationType, value: notifications.oneMonthBefore },
            ];

            notificationTypes.forEach(notification => {
                /* A non-negative integer: This represents the index of the element in the newEmailNotifications array
                 that satisfies the condition n.notificationType === notification.type.

                 -1: This value indicates that no element in the newEmailNotifications array satisfies the condition*/
                const existingNotificationIndex = newEmailNotifications.findIndex(
                    n => n.notificationType === notification.type
                );

                if (notification.value) {
                    const newNotificationDate = calculateNotificationDate(newDeadlineDate, notification.type);
                    const newNotification = {
                        notificationType: notification.type,
                        notificationDate: newNotificationDate
                    };

                    if (existingNotificationIndex === -1) {
                        newEmailNotifications.push(newNotification);
                    } else {
                        const existingNotification = newEmailNotifications[existingNotificationIndex];
                        const newNotificationDateFormatted = formatDateYYYYmmDD(newNotificationDate);
                        if (existingNotification.notificationDate !== newNotificationDateFormatted) {
                            newEmailNotifications[existingNotificationIndex] = newNotification;
                        }
                    }
                } else {
                    /* If notification.value is false, check existing notification index and 
                       if exist remove the existing notification from the array at the existingNotificationIndex.*/
                    if (existingNotificationIndex !== -1) {
                        newEmailNotifications.splice(existingNotificationIndex, 1);
                    }
                }
            });

            const requestBody = {
                id: editId,
                serviceName: newServiceName,
                deadlineDate: newDeadlineDate,
                insertDate: newInsertDate,
                documentationRefs,
                category: { id: newSelectedCategoryId },
                tipology: { id: newSelectedTipologyId },
                emailNotifications: newEmailNotifications,
                ...(selectedCustomer && { customer: { id: selectedCustomer.id } }),
                ...(selectedEmailTemplate && { emailTemplate: { id: selectedEmailTemplate.id } })
            };

            setNotifications({
                oneDayBefore: false,
                oneWeekBefore: false,
                oneMonthBefore: false,
            });

            const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines`;
            const jsonData = await fetchWithHandling(
                uri,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                    body: JSON.stringify(requestBody)
                },
                () => {
                    toast.error(
                        "Sessione scaduta. Effettua nuovamente l'accesso.", {
                        position: "bottom-right",
                        onClose: () => {
                            keycloak.logout();
                            localStorage.removeItem("kc-token");
                        }
                    }
                    );
                }
            );

            if (jsonData) {
                fetchDeadlines(moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD"));
                closeAddModal();
                toast.success(
                    "Scadenza modificata con successo", {
                    position: "bottom-right",
                }
                );
                closeEditModal();
            } else {
                toast.error(
                    "Errore durante la modifica della scadenza", {
                    position: "bottom-right",
                });
            }

            setLoading(false);
        } catch (error) {
            setBackendError(true);
            setLoading(false);
        }
    };

    const handleFetchDeadlines = async () => {
        const start = dateRange.startDate || moment().startOf("month").toDate();
        const end = dateRange.endDate || moment().endOf("month").toDate();

        await fetchDeadlines(moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD"));

        if (!backendError) {
            toast.success(
                "Scadenze calendario aggiornate con successo", {
                position: "bottom-right",
            }
            );
        }
    };
    
    return (
        <Box
            width={{ base: "100%", md: "96%" }}
            marginLeft={{ base: "0px", md: "0px" }}
            py="10px"
            backgroundColor="white"
            borderRadius="20px"
            boxShadow={shadow}
            mb="20px"
            alignItems="center"
            justifyContent="center"
        >
            <Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
                <Flex h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'end', xl: 'end' }}>
                    <Button
                        onClick={() => handleFetchDeadlines()}
                        _hover={{ bg: 'gray.700' }}
                        _active={{ bg: 'gray.600' }}
                        _focus={{ boxShadow: 'none' }}
                        backgroundColor='#688CC8'
                        color="white"
                        style={{
                            height: '30px',
                            borderRadius: '6px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                        fontSize='sm'
                        isLoading={loading}
                        disabled={loading}
                    >
                        {!loading && <Icon as={FaSync} w={4} h={4} mr={2} />}
                        {loading ? "Caricamento..." : "Aggiorna"}
                    </Button>
                </Flex>
            </Card>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w={{ base: "100%", lg: "95%" }}
                    h={{ base: "60vh", lg: "85vh" }}
                >
                    <Calendar
                        views={{ month: true, day: true }}
                        localizer={localizer}
                        events={events}
                        defaultDate={new Date()}
                        defaultView="month"
                        startAccessor="start"
                        endAccessor="end"
                        selectable
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                        messages={bigCalendarMessages}
                        onSelectSlot={openAddModal}
                        onSelectEvent={openEditModal}
                        onRangeChange={handleRangeChange}
                        dayLayoutAlgorithm="no-overlap"
                    />
                </Box>
                {/* Add Modal */}
                <Modal isOpen={isAddModalOpen} onClose={closeAddModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader color="#9FB50C">Nuova Scadenza</ModalHeader>
                        <ModalCloseButton _focus={{ boxShadow: 'none' }} />
                        <ModalBody>
                            <Text fontWeight="bold">
                                Nome Servizio <Text as="span" color="red">*</Text>
                            </Text>
                            <Input
                                _focus={{ boxShadow: 'black' }}
                                placeholder="Nome Servizio"
                                width="100%"
                                mb="4"
                                value={serviceName}
                                onChange={(e) => setServiceName(e.target.value)}
                            />
                            <Flex justifyContent="space-between" gap="4">
                                <Box flex="1">
                                    <Text fontWeight="bold">
                                        Categoria <Text as="span" color="red">*</Text>
                                    </Text>
                                    <Menu>
                                        <MenuButton
                                            as={Button}
                                            rightIcon={<IoIosArrowDown />}
                                            variant="subtle"
                                            color="var(--chakra-colors-secondaryGray-600)"
                                            textAlign="left"
                                            fontWeight="normal"
                                            border="1px solid"
                                            borderColor="gray.200"
                                            borderRadius="0.375rem"
                                            width="100%"
                                            mb="4"
                                        >
                                            {deadlinesCategory.find((source) => source.id === selectedCategoryId)?.name || "Categoria"}
                                        </MenuButton>
                                        <MenuList textAlign="left" maxHeight="220px" overflowY="auto">
                                            {deadlinesCategory.map((source) => (
                                                <MenuItem _hover={{ bg: 'gray.200' }} key={source.id} onClick={() => setSelectedCategoryId(source.id)}>
                                                    {source.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                </Box>
                                <Box flex="1">
                                    <Text fontWeight="bold">
                                        Tipologia <Text as="span" color="red">*</Text>
                                    </Text>
                                    <Menu>
                                        <MenuButton
                                            as={Button}
                                            rightIcon={<IoIosArrowDown />}
                                            variant="subtle"
                                            color="var(--chakra-colors-secondaryGray-600)"
                                            textAlign="left"
                                            fontWeight="normal"
                                            border="1px solid"
                                            borderColor="gray.200"
                                            borderRadius="0.375rem"
                                            width="100%"
                                            mb="4"
                                        >
                                            {deadlinesTipology.find((consumer) => consumer.id === selectedTipologyId)?.name || "Tipologia"}
                                        </MenuButton>
                                        <MenuList textAlign="left" maxHeight="220px" overflowY="auto">
                                            {deadlinesTipology.map((consumer) => (
                                                <MenuItem _hover={{ bg: 'gray.200' }} key={consumer.id} onClick={() => setSelectedTipologyId(consumer.id)}>
                                                    {consumer.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                </Box>
                            </Flex>

                            <Text fontWeight="bold">
                                Data Scadenza <Text as="span" color="red">*</Text>
                            </Text>
                            <Input
                                borderRadius='6px'
                                type="date"
                                focusBorderColor="gray.300"
                                _hover={{ borderColor: 'gray.300' }}
                                color="gray.400"
                                width="100%"
                                mb="4"
                                value={deadlineDate}
                                onChange={(e) => setDeadlineDate(e.target.value)}
                            />
                            <Text fontWeight="bold">Link Documentazione</Text>
                            <Input
                                borderRadius='6px'
                                type="url"
                                placeholder="Link Documentazione"
                                focusBorderColor="gray.300"
                                _hover={{ borderColor: 'gray.300' }}
                                color="gray.400"
                                width="100%"
                                mb="4"
                                value={documentationRefs}
                                onChange={(e) => setDocumentationRefs(e.target.value)}
                            />
                            <Text fontWeight="bold">Notifica Cliente</Text>
                            <Flex gap={4}>
                                <Menu placement="bottom" flip={false}>
                                    <MenuButton
                                        borderRadius='6px'
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        bg="gray.200"
                                        onClick={() => {
                                            if (emailTemplates?.length === 0) {
                                                fetchCustomers();
                                            }
                                        }}
                                    >
                                        {selectedCustomer ? (truncateName(selectedCustomer?.fullName, 21) || 'Cliente') : 'Cliente'}
                                    </MenuButton>
                                    <MenuList maxHeight="250px" overflowY="auto" width="350px">
                                        <Box p="2">
                                            <InputGroup>
                                                <Input
                                                    placeholder="Cerca per ragione sociale..."
                                                    value={searchedCustomer}
                                                    onChange={(e) => setSearchedCustomer(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            fetchCustomers(searchedCustomer); // Trigger search on Enter
                                                        }
                                                    }}
                                                />
                                                <InputRightElement width="3rem">
                                                    <Button h="1.75rem" size="sm" onClick={() => fetchCustomers(searchedCustomer)}>
                                                        <SearchIcon />
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </Box>
                                        {customers?.content?.map((customer: Customer) => (
                                            <MenuItem key={customer.id} onClick={() => handleCustomerSelect(customer)}>
                                                {customer.fullName}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </Menu>
                                <Menu placement="bottom" preventOverflow={false} flip={false}>
                                    <MenuButton
                                        borderRadius='6px'
                                        as={Button} rightIcon={<ChevronDownIcon />}
                                        bg="gray.200"
                                        onClick={() => {
                                            if (emailTemplates?.length === 0) {
                                                fetchEmailTemplates();
                                            }
                                        }}
                                    >
                                        {selectedEmailTemplate?.name || 'Modello Email'}
                                    </MenuButton>
                                    <MenuList maxHeight="250px" overflowY="auto">
                                        <Box p="2">
                                            <Input
                                                placeholder="Cerca modello..."
                                                value={searchedEmailTemplate}
                                                onChange={(e) => setSearchedEmailTemplate(e.target.value)}
                                            />
                                        </Box>
                                        {filteredEmailTemplate.length === 0 ? (
                                            <Spinner size="md" />
                                        ) : (
                                            filteredEmailTemplate.map((template: EmailTemplate) => (
                                                <MenuItem key={template.id} onClick={() => handleEmailTemplateSelect(template)}>
                                                    {template.name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </MenuList>
                                </Menu>
                            </Flex>
                            <Text fontWeight="bold" mt='4'>Notifiche</Text>
                            <Checkbox
                                mb="2"
                                name="oneDayBefore"
                                isChecked={notifications.oneDayBefore}
                                onChange={handleCheckboxChange}
                            >
                                Avviso di scadenza un giorno prima
                            </Checkbox>
                            <Checkbox
                                mb="2"
                                name="oneWeekBefore"
                                isChecked={notifications.oneWeekBefore}
                                onChange={handleCheckboxChange}
                            >
                                Avviso di scadenza una settimana prima
                            </Checkbox>
                            <Checkbox
                                name="oneMonthBefore"
                                isChecked={notifications.oneMonthBefore}
                                onChange={handleCheckboxChange}
                            >
                                Avviso di scadenza un mese prima
                            </Checkbox>
                        </ModalBody>
                        <ModalFooter>
                            <Tooltip
                                label="Inserisci Nome Servizio, Categoria, Tipologia e Data Scadenza per salvare"
                                isDisabled={!isSaveDisabled}
                                shouldWrapChildren
                            >
                                <Button
                                    colorScheme={isSaveDisabled ? "red" : "#688CC8"}
                                    backgroundColor={isSaveDisabled ? 'red.500' : '#688CC8'}
                                    color="white"
                                    mr={3}
                                    onClick={addDeadline}
                                    isLoading={loading}
                                    disabled={isSaveDisabled}
                                >
                                    Salva
                                </Button>
                            </Tooltip>
                            <Button colorScheme="gray" onClick={closeAddModal}>Annulla</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {/* Edit Modal */}
                <Modal isOpen={!!editId} onClose={closeEditModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader color="#9FB50C">Modifica Scadenza</ModalHeader>
                        <ModalCloseButton _focus={{ boxShadow: 'none' }} />
                        <ModalBody>
                            <Text fontWeight="bold">
                                Nome Servizio <Text as="span" color="red">*</Text>
                            </Text>
                            <Input
                                _focus={{ boxShadow: 'black' }}
                                placeholder="Nome Servizio"
                                width="100%"
                                mb="4"
                                value={newServiceName}
                                onChange={(e) => setNewServiceName(e.target.value)}
                            />
                            <Flex justifyContent="space-between" gap="4">
                                <Box flex="1">
                                    <Text fontWeight="bold">
                                        Categoria <Text as="span" color="red">*</Text>
                                    </Text>
                                    <Menu>
                                        <MenuButton
                                            as={Button}
                                            rightIcon={<IoIosArrowDown />}
                                            variant="subtle"
                                            color="var(--chakra-colors-secondaryGray-600)"
                                            textAlign="left"
                                            fontWeight="normal"
                                            border="1px solid"
                                            borderColor="gray.200"
                                            borderRadius="0.375rem"
                                            width="100%"
                                            mb="4"
                                        >
                                            {deadlinesCategory.find((category) => category.id === newSelectedCategoryId)?.name || "Categoria"}
                                        </MenuButton>
                                        <MenuList textAlign="left" maxHeight="220px" overflowY="auto">
                                            {deadlinesCategory.map((category) => (
                                                <MenuItem _hover={{ bg: 'gray.200' }} key={category.id} onClick={() => setNewSelectedCategoryId(category.id)}>
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                </Box>
                                <Box flex="1">
                                    <Text fontWeight="bold">
                                        Tipologia <Text as="span" color="red">*</Text>
                                    </Text>
                                    <Menu>
                                        <MenuButton
                                            as={Button}
                                            rightIcon={<IoIosArrowDown />}
                                            variant="subtle"
                                            color="var(--chakra-colors-secondaryGray-600)"
                                            textAlign="left"
                                            fontWeight="normal"
                                            border="1px solid"
                                            borderColor="gray.200"
                                            borderRadius="0.375rem"
                                            width="100%"
                                            mb="4"
                                        >
                                            {deadlinesTipology.find((tipology) => tipology.id === newSelectedTipologyId)?.name || "Tipologia"}
                                        </MenuButton>
                                        <MenuList textAlign="left" maxHeight="220px" overflowY="auto">
                                            {deadlinesTipology.map((tipology) => (
                                                <MenuItem _hover={{ bg: 'gray.200' }} key={tipology.id} onClick={() => setNewSelectedTipologyId(tipology.id)}>
                                                    {tipology.name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                </Box>
                            </Flex>
                            <Text fontWeight="bold" mt='2%'>
                                Data Scadenza <Text as="span" color="red">*</Text>
                            </Text>
                            <Input
                                borderRadius='6px'
                                type="date"
                                focusBorderColor="gray.300"
                                _hover={{ borderColor: 'gray.300' }}
                                color="gray.400"
                                width="100%"
                                mb="4"
                                value={newDeadlineDate}
                                onChange={(e) => setNewDeadlineDate(e.target.value)}
                            />
                            <Text fontWeight="bold" mt='2%'>Link Documentazione</Text>
                            <Input
                                borderRadius='6px'
                                type="url"
                                placeholder="Link Documentazione"
                                focusBorderColor="gray.300"
                                _hover={{ borderColor: 'gray.300' }}
                                color="gray.400"
                                width="100%"
                                mb="4"
                                value={documentationRefs}
                                onChange={(e) => setDocumentationRefs(e.target.value)}
                            />
                            <Text fontWeight="bold">Notifica Cliente</Text>
                            <Flex gap={4}>
                                <Menu placement="bottom" flip={false}>
                                    <MenuButton
                                        borderRadius='6px'
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        bg="gray.200"
                                        onClick={() => {
                                            if (emailTemplates?.length === 0) {
                                                fetchCustomers();
                                            }
                                        }}
                                    >
                                        {selectedCustomer ? (truncateName(selectedCustomer?.fullName, 21) || 'Cliente') : 'Cliente'}
                                    </MenuButton>
                                    <MenuList maxHeight="250px" overflowY="auto" width="350px">
                                        <Box p="2">
                                            <InputGroup>
                                                <Input
                                                    placeholder="Cerca per ragione sociale..."
                                                    value={searchedCustomer}
                                                    onChange={(e) => setSearchedCustomer(e.target.value)} // Update the search term
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            fetchCustomers(searchedCustomer);
                                                        }
                                                    }}
                                                />
                                                <InputRightElement width="3rem">
                                                    <Button h="1.75rem" size="sm" onClick={() => fetchCustomers(searchedCustomer)}>
                                                        <SearchIcon />
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </Box>
                                        {customers?.content?.map((customer: Customer) => (
                                            <MenuItem key={customer.id} onClick={() => handleCustomerSelect(customer)}>
                                                {customer.fullName}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </Menu>
                                <Menu placement="bottom" preventOverflow={false} flip={false}>
                                    <MenuButton
                                        borderRadius='6px'
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        bg="gray.200"
                                        onClick={() => {
                                            if (emailTemplates?.length === 0) {
                                                fetchEmailTemplates();
                                            }
                                        }}
                                    >
                                        {selectedEmailTemplate?.name || 'Modello Email'}
                                    </MenuButton>
                                    <MenuList maxHeight="250px" overflowY="auto">
                                        <Box p="2">
                                            <Input
                                                placeholder="Cerca modello..."
                                                value={searchedEmailTemplate}
                                                onChange={(e) => setSearchedEmailTemplate(e.target.value)}
                                            />
                                        </Box>
                                        {filteredEmailTemplate.length === 0 ? (
                                            <Spinner size="md" />
                                        ) : (
                                            filteredEmailTemplate.map((template: EmailTemplate) => (
                                                <MenuItem key={template.id} onClick={() => handleEmailTemplateSelect(template)}>
                                                    {template.name}
                                                </MenuItem>
                                            ))
                                        )}
                                    </MenuList>
                                </Menu>
                            </Flex>
                            <Text fontWeight="bold" mt='4'>Notifiche</Text>
                            <Checkbox
                                name="oneDayBefore"
                                isChecked={notifications.oneDayBefore}
                                onChange={handleCheckboxChange}
                                mb="2"
                                mt='2'
                            >
                                Avviso di scadenza un giorno prima
                            </Checkbox>
                            <Checkbox
                                name="oneWeekBefore"
                                isChecked={notifications.oneWeekBefore}
                                onChange={handleCheckboxChange}
                                mb="2"
                            >
                                Avviso di scadenza una settimana prima
                            </Checkbox>
                            <Checkbox
                                name="oneMonthBefore"
                                isChecked={notifications.oneMonthBefore}
                                onChange={handleCheckboxChange}
                                mb="4"
                            >
                                Avviso di scadenza un mese prima
                            </Checkbox>
                        </ModalBody>
                        <ModalFooter>
                            <Tooltip
                                label="Inserisci Nome Servizio, Categoria, Tipologia e Data Scadenza per salvare"
                                isDisabled={!isSaveDisabled}
                                shouldWrapChildren
                            >
                                <Button
                                    colorScheme={isSaveDisabled ? "red" : "#688CC8"}
                                    backgroundColor={isSaveDisabled ? 'red.500' : '#688CC8'}
                                    color="white"
                                    mr={3}
                                    onClick={editDeadline}
                                    isLoading={loading}
                                    disabled={isSaveDisabled}
                                >
                                    Modifica
                                </Button>
                            </Tooltip>
                            <Tooltip label="Elimina" fontSize="md">
                                <Button
                                    backgroundColor={'red.500'}
                                    color="white"
                                    mr={3}
                                    onClick={openDeleteModal}
                                    isLoading={loading}
                                    disabled={isSaveDisabled}
                                >
                                    Elimina
                                </Button>
                            </Tooltip>
                            <Button colorScheme="gray" onClick={closeEditModal}>Annulla</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {/* Conferma eliminazione */}
                <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader color="#9FB50C">Conferma Eliminazione</ModalHeader>
                        <ModalCloseButton _focus={{ boxShadow: 'none' }} />
                        <ModalBody>
                            Sei sicuro di voler eliminare questa scadenza?
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="red" mr={3} onClick={confirmDelete}>
                                Elimina
                            </Button>
                            <Button colorScheme="gray" onClick={closeDeleteModal}>
                                Annulla
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </Box>
    );
};

export default DeadlinesCalendar;